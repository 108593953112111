import React from 'react'
import { Alert, Button, Col, Form, Row } from 'react-bootstrap'
import { useGetCountriesQuery } from '../../components/api'
import { CreateCountrySelect } from '../../components/Form'
import { unauthNavTo } from '../../components/navigation'
import Spinner from '../../components/spinner'
import { useUploadCompanyProfile } from '../../utils/api'
const Form4DeliveryAddress = (props) => {
  // state
  const [isSameAsCompanyAddress, setIsSameAsCompanyAddress] = React.useState(false)

  const countriesQuery = useGetCountriesQuery()
  const uploadCompanyFile = useUploadCompanyProfile()

  const { handleChange, values, setFormTwo } = props
  const goContinue = (e) => {
    const form = e.currentTarget.form
    if (form.checkValidity() === false) {
      e.stopPropagation()
    } else if (form.checkValidity() === true) {
      e.preventDefault()
      handleSubmit()
    }

    // props.nextStep()
  }
  // check if it is success then route to other page
  React.useEffect(() => {
    if (uploadCompanyFile.isSuccess) {
      if (values.token) {
        unauthNavTo.dashboardPage()
      } else {
        props.nextStep()
      }
    }
  }, [props, uploadCompanyFile, values.token])

  const handleSubmit = () => {
    const companyType = 'office'
    // format the
    const toSubmitFormData = {
      legalName: values.companyName,
      // companyNumber:values.companyRegiNum,
      registrationNumber: values.companyRegiNum,
      taxId: values.companyTaxId,
      website: values.companyWebsite,
      registrationDocument: values.companyDocumentFile,
      'address[addressLine1]': values.addressLine1,
      'address[addressLine2]': values.addressLine2,
      'address[city]': values.city,
      'address[state]': values.state,
      'address[zipCode]': values.zipCode,
      'address[country]': values.country,
      'deliveryAddress[addressLine1]': values.deliveryAddressLine1,
      'deliveryAddress[addressLine2]': values.deliveryAddressLine2,
      'deliveryAddress[city]': values.deliveryCity,
      'deliveryAddress[state]': values.deliveryState,
      'deliveryAddress[zipCode]': values.deliveryZipCode,
      'deliveryAddress[country]': values.deliveryCountry,
      'address[type]': companyType
    }
    console.log(toSubmitFormData)
    uploadCompanyFile.mutate({ companyProfile: toSubmitFormData, token: values.token })
  }
  React.useEffect(() => {
    if (uploadCompanyFile.isSuccess) {
      props.nextStep()
    }
  }, [props, uploadCompanyFile])

  // if check the checkbox, then set the delivery address to be same with company address
  React.useEffect(() => {
    if (isSameAsCompanyAddress) {
      setFormTwo((ps) => ({
        ...ps,
        deliveryAddressLine1: values.addressLine1,
        deliveryAddressLine2: values.addressLine2,
        deliveryCity: values.city,
        deliveryState: values.state,
        deliveryZipCode: values.zipCode,
        deliveryCountry: values.country
      }))
    }
  }, [isSameAsCompanyAddress, setFormTwo, values.addressLine1, values.addressLine2, values.city, values.country, values.state, values.zipCode])
  const goBack = (e) => {
    e.preventDefault()
    props.prevStep()
  }

  return (
    <div className="px-4 px-md-5 d-flex flex-column flex-grow-1 my-5">
      {/* <h5 className="d-flex justify-content-end my-0">1/4</h5> */}
      <div className="d-flex justify-content-between">
        <h4 className="mb-3 font-weight-bold inter-font-content">Provide your delivery address </h4>
        <h4 className="mb-3 font-weight-bold inter-font-content">5/5</h4>
      </div>
      <Col
      className={` mt-2  py-1 ${
        isSameAsCompanyAddress ? 'highlighted-checkbox' : ''
      }`}
    >
      <Form.Check type="checkbox" id="cb-1">
        <Form.Check.Input
          type="checkbox"
          checked={isSameAsCompanyAddress}
          onChange={(e) => {
            setIsSameAsCompanyAddress(e.target.checked)
          }}
        />
        <Form.Check.Label className="cursor-pointer ml-2" style={{ fontSize: '16px', fontFamily: 'Inter, sans-serif ' }}>
          Same as company address{' '}
        </Form.Check.Label>
      </Form.Check>
    </Col>
      {/* form */}
      <Form className="d-flex flex-column flex-grow-1 justify-content-between">
        <div>
          <Form.Group>
            <Form.Label>Address line 1</Form.Label>
            <Form.Control
              type="text"
              required
              autoFocus={true}
              autoComplete="address-line1"
              value={values.deliveryAddressLine1}
              onChange={handleChange('deliveryAddressLine1')}
            />
          </Form.Group>
          <Form.Group>
            <Form.Label>Address line 2</Form.Label>
            <Form.Control
              type="text"
              autoComplete="nope"
              value={values.deliveryAddressLine2}
              onChange={handleChange('deliveryAddressLine2')}
            />
          </Form.Group>
          <Form.Group>
            <Form.Label>City</Form.Label>
            <Form.Control
              type="text"
              required
              value={values.deliveryCity}
              autoComplete="address-level2"
              onChange={handleChange('deliveryCity')}
            />
          </Form.Group>
          <Row>
            <Col>
              <Form.Group>
                <Form.Label>State</Form.Label>
                <Form.Control
                  type="text"
                  required
                  value={values.deliveryState}
                  autoComplete="address-level1"
                  onChange={handleChange('deliveryState')}
                />
              </Form.Group>
            </Col>
            <Col>
              <Form.Group>
                <Form.Label>Zip code</Form.Label>
                <Form.Control
                  type="text"
                  required
                  autoComplete="postal-code"
                  value={values.deliveryZipCode}
                  onChange={handleChange('deliveryZipCode')}
                />
              </Form.Group>
            </Col>
          </Row>
        </div>

        <CreateCountrySelect
          label="Country"
          required
          defaultValue=""
          value={values.deliveryCountry}
          onChange={handleChange('deliveryCountry')}
          placeholder="Select an country"
          disabled={false}
          options={countriesQuery.isSuccess ? countriesQuery.data : []}
        />

        <Form.Group className="mb-0 d-flex justify-content-between">
          <Button
            variant="outline-primary"
            type="button"
            style={{ width: 100 + 'px' }}
            onClick={(e) => goBack(e)}
          >
            <span>Back</span>
          </Button>
          <Button
            className="mt-0 "
            type="submit"
            // disabled={loginMutation.isLoading || credentials.username.length === 0 || credentials.password.length === 0}
            onClick={(e) => goContinue(e)}
          >
          {uploadCompanyFile.isLoading
            ? (
            <Spinner />

              )
            : (
            <span>Submit</span>
              )}
          </Button>
        </Form.Group>
        {uploadCompanyFile.isError && (
          <Alert variant="danger" className="mb-0 mt-3 text-center">
            {uploadCompanyFile.error.response.data.message}
          </Alert>
        )}
      </Form>
    </div>
  )
}

export default Form4DeliveryAddress
